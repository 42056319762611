import React from "react"
import { graphql } from "gatsby"
import Title from "../components/Title"
import Seo from "../components/Seo"

const About = () => {
  return (
    <>
      <h2>about page</h2>
    </>
  )
}

export default About
